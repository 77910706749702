import BasicStructure from '../../components/basic-structure/basic-structure';
import React, { useState } from 'react';
import TitleMetaTags from '../../components/title-meta-tags/title-meta-tags';
import ContentContainer from '../../components/content-container/content-container';
import { graphql } from 'gatsby';
import { getWpBlock } from '../../components/wp-blocks';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import Menubar from '../../components/menu/menubar';
import BurgerMenu from '../../components/burgermenu/burgermenu';
import './programmbeispiel.css';
import Sidebar from '../../components/sidebar/sidebar';
import ScrollUp from '../../components/scroll-up/scroll-up';
import ProgrammbeispielNavigation from '../../components/side-bar-components/programmbeispiel-navigation';

interface Props {
  data: Data;
  pageContext: PageContext;
}

interface Data {
  allWpPost: any;
}

interface PageContext {
  categoryTitle: string;
  categoryUrl: string;
  subcategorySlug: string;
  subcategoryTitle: string;
  subcategoryUrl: string;

  startYear: number;
  endYear: number;
}

function groupNodeByMonth(allNode: any[], startYear): Record<string, any> {
  const nodeByMonth = {};
  if (allNode == undefined) {
    throw Error('Posts are undefined!');
  }
  allNode.forEach((node) => {
    const date = new Date(node.date);
    const month = `${date.toLocaleString('de', {
      month: 'long',
    })} ${startYear}`;
    if (nodeByMonth.hasOwnProperty(month)) {
      nodeByMonth[month] = [node, ...nodeByMonth[month]];
    } else {
      nodeByMonth[month] = [node];
    }
  });
  return nodeByMonth;
}

function buildProgrammbeispiel(block, key: string) {
  const WpBlock = getWpBlock(block.__typename);
  if (!WpBlock) return null;
  return (
    <div className="content-block" key={key}>
      <WpBlock block={block} />
    </div>
  );
}

export default function Programmbeispiel(props: Props): JSX.Element {
  // console.log('programmbeispiel, data', props.data);
  const posts = props.data.allWpPost?.edges.map((edge) => edge.node);

  const {
    categoryTitle,
    categoryUrl,
    subcategorySlug,
    subcategoryTitle,
    subcategoryUrl,
    startdate,
    startYear,
    enddate,
    endYear,
  } = props.pageContext;

  // console.log(subcategorySlug);

  const allNodeByMonth = groupNodeByMonth(posts, startYear);

  const noMonth = '';
  const [state, setState] = useState({
    clickedMonth: noMonth,
  });

  const handleMonthChange = (event) => {
    const { clickedMonth } = state;
    const newMonth: string =
      clickedMonth !== event.target.innerHTML ? event.target.innerHTML : '';
    setState({
      clickedMonth: newMonth,
    });
  };

  const { clickedMonth } = state;

  return (
    <BasicStructure>
      <TitleMetaTags
        title={`Programmbeispiele ${startYear} | ${subcategoryTitle}`}
      />
      <Header>
        <Menubar styleClass="main-menu" />
        <BurgerMenu>
          <ProgrammbeispielNavigation parentPageUri={subcategoryUrl} />
        </BurgerMenu>
      </Header>
      <Sidebar>
        <ProgrammbeispielNavigation parentPageUri={subcategoryUrl} />
        <ScrollUp />
      </Sidebar>
      <ContentContainer>
        <a href={categoryUrl}>
          <h1 className="programm_beispiele_category_title category-title">
            {categoryTitle}
          </h1>
        </a>
        <h2 className="programm_beispiele_title">
          {`Programmbeispiele ${startYear} | ${subcategoryTitle}`}
        </h2>
        <section className="programm_beispiele_page">
          {allNodeByMonth &&
            Object.keys(allNodeByMonth).map((month) => {
              return (
                <div
                  className={'programmbeispiele_for_month'}
                  key={`programmbeispiele-for-${month}`}
                >
                  <h3
                    className={`heading-square ${
                      month !== clickedMonth
                        ? 'heading_triangle'
                        : 'heading_triangle_upsidedown'
                    }`}
                    onClick={handleMonthChange.bind(month)}
                  >
                    {month}
                  </h3>
                  <div
                    className={`${
                      month === clickedMonth
                        ? 'active_programmbeispiele_items'
                        : 'programmbeispiele_items'
                    }`}
                    key={`programmbeispiele-items-${month}`}
                  >
                    {allNodeByMonth[month].map((programmbeispiel) => {
                      return (
                        programmbeispiel.blocks.length > 0 &&
                        programmbeispiel.blocks.map((block, index) =>
                          buildProgrammbeispiel(
                            block,
                            `programmbeispiele-items-${month}-${index}`,
                          ),
                        )
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </section>
      </ContentContainer>
      <Footer />
    </BasicStructure>
  );
}

export const pageQuery = graphql`
  query ($subcategorySlug: String!, $startdate: Date!, $enddate: Date!) {
    allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: $subcategorySlug } } } }
        date: { gt: $startdate, lt: $enddate }
      }
      sort: { fields: date, order: ASC }
    ) {
      edges {
        node {
          id
          title
          date
          blocks {
            __typename
            dynamicContent
            isDynamic
            originalContent
            saveContent
            ...WPColumns
            ...WpCoreImageBlockFragment
            ...WpCoreGalleryBlockFragment

            innerBlocks {
              __typename
              saveContent
              isDynamic
              dynamicContent
              ...WpCoreImageBlockForGalleryFragment
              ...WpCoreGalleryBlockFragment
            }
          }
        }
      }
    }
  }
`;
