import React from 'react';
import './project-menu.css';

export interface VeranstaltungMenuProps {
  parentPageUri: string;
}

function ProgrammbeispielNavigation({
  parentPageUri,
}: VeranstaltungMenuProps): JSX.Element {
  return (
    <div className="project-menu__container programmbeispiel__sidebar">
      <a className="programm_beispiele_navigation" href={parentPageUri}>
        Zurück zu den Terminen
      </a>
    </div>
  );
}

export default ProgrammbeispielNavigation;
